import AudioSVGWaveform  from './audio-waveform-svg-path';

const color = '#fff';

const height = 200;

function buildSVG(path) {
    return new Promise((resolve) => {
        const tempsvg = `<?xml version="1.0" encoding="UTF-8" standalone="no"?><svg width="200" height="${height}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;"><g transform="matrix(0.048828125,0,0,${height*0.7},0,${height*0.5})"><path d="${path}" fill="none" stroke="${color}" height="100%" width="100%" x="0" y="0" /></g></svg>`;
        resolve(tempsvg);
    });
}

export async function generateSvgWaveform(buffer) {

    const { path } = await AudioSVGWaveform(buffer);
    const svg = await buildSVG(path);

    return svg;
}