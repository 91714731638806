import { apiBaseUrl } from "../../config/environment";

export async function upload(jwt, files, data){
    const url = `${apiBaseUrl}tracks`;
    
    const formData = new FormData()
    formData.append('files.file', files.file[0])
    formData.append('data', JSON.stringify(data))

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${jwt}`,
        },
        body: formData,
    });

    if(response.status === 400){
        const { message } = await response.json();
        return { ok: false, error: message[0].messages[0] }
    }

    if(!response.ok){
        return { ok: false };
    }

    const track = await response.json();
    return { ok: true, track };
}

export async function uploadImage(jwt, trackUid, image){
    const url = `${apiBaseUrl}tracks/upload-image`;
    
    const data = {trackUid};
    const formData = new FormData()
    formData.append('data', JSON.stringify(data))
    formData.append('files.image', image)

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${jwt}`,
        },
        body: formData,
    });

    if(response.status === 400){
        const { message } = await response.json();
        return { ok: false, error: message[0].messages[0] }
    }

    if(!response.ok){
        return { ok: false };
    }

    const track = await response.json();
    return { ok: true, track };
}

export async function loadTrack(uid){
    const url = `${apiBaseUrl}tracks/${uid}`;
    
    const response = await fetch(url, {
        method: 'GET'
    });

    if(!response.ok){
        return { ok: false };
    }

    const track = await response.json();
    return { ok: true, track };
}

export async function loadPlaylist(uid){
    const url = `${apiBaseUrl}playlists/${uid}`;
    
    const response = await fetch(url, {
        method: 'GET'
    });

    if(!response.ok){
        return { ok: false };
    }

    const playlist = await response.json();
    return { ok: true, playlist };
}


export async function fetchMine(jwt){
    const url = `${apiBaseUrl}tracks/mine`;
    
    const response = await fetch(url, {
        method: 'GET',
        headers: {  
            'Authorization': `Bearer ${jwt}`,
        },
    });

    if(!response.ok){
        return { ok: false };
    }

    const { tracks, playlists } = await response.json();
    return { tracks, playlists, ok: true };
}


export async function comment(jwt, authenticated, data){

    const uid = data.track;
    delete data.track;
    
    const url = `${apiBaseUrl}tracks/${uid}/comment`;

    const authorizationHeader = authenticated ? {
        'Authorization': `Bearer ${jwt}`
    } : {};
    const response = await fetch(url, {
        method: 'POST',
        headers: {  
            ...authorizationHeader,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });

    if(!response.ok){
        return { ok: false };
    }

    const track = await response.json();
    return { ok: true, track };
}

export async function deleteTrack(jwt, track){
    const url = `${apiBaseUrl}tracks/${track.uid}`;

    const response = await fetch(url, {
        method: 'DELETE',
        headers: {  
            'Authorization': `Bearer ${jwt}`,
        },
    });

    if(!response.ok){
        return { ok: false };
    }

    return { ok: true, track };
}

export async function deleteImage(jwt, trackUid, image){
    const url = `${apiBaseUrl}tracks/${trackUid}/image/${image.id}`;

    const response = await fetch(url, {
        method: 'DELETE',
        headers: {  
            'Authorization': `Bearer ${jwt}`,
        },
    });

    if(!response.ok){
        return { ok: false };
    }

    const track = await response.json();
    return { ok: true, track };
}

export async function updateImages(jwt, trackUid, images){
    const url = `${apiBaseUrl}tracks/${trackUid}`;

    const data = {images: images.map(image => ({ id: image.id, caption: image.caption }))};

    const response = await fetch(url, {
        method: 'PUT',
        headers: {  
            'Authorization': `Bearer ${jwt}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });

    if(!response.ok){
        return { ok: false };
    }

    const track = await response.json();
    return { ok: true, track };
}

export async function deleteComment(jwt, trackUid, comment){
    const url = `${apiBaseUrl}tracks/${trackUid}/comment/${comment.id}`;

    const response = await fetch(url, {
        method: 'DELETE',
        headers: {  
            'Authorization': `Bearer ${jwt}`
        }
    });

    if(!response.ok){
        return { ok: false };
    }

    const track = await response.json();
    return { ok: true, track };
}

export async function updatePlaylists(jwt, data){
    data = { playlists: data.map(playlist => {
        const { id, name, tracks } = playlist;
        return ({ id, name, tracks: tracks.map(track => ({ id: track.id, track: track.track.id }))});
    }) }

    const url = `${apiBaseUrl}playlists`;

    const response = await fetch(url, {
        method: 'PUT',
        headers: {  
            'Authorization': `Bearer ${jwt}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });

    if(!response.ok){
        return { ok: false };
    }

    const {playlists} = await response.json();
    return { ok: true, playlists };
}