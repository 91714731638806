import { generateSvgWaveform } from "./audio-metadata";

function getImageDataURL(svgXml) {
    return "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svgXml)));
}

export default function useTrackWaveformGenerator(){
    return async (file) => {
		if(!file) {
			return null;
		}

		try {
			const buffer = await file.arrayBuffer();
			const svg = await generateSvgWaveform(buffer)
	
			const url = getImageDataURL(svg);
	
			return url;
		}
		catch(e){
			console.warn('Error generating waveform:', e)
			return null;
		}
	}
}